import { classnames } from 'begonya/functions'
import { SectionHeadProps } from './types'

const SectionHead: React.FunctionComponent<SectionHeadProps> = ({
  children,
  left,
  right,
  isReverse,
}) => {
  return (
    <div
      className={classnames(
        'section-head',
        left && 'section-head--left',
        right && 'section-head--right',
        isReverse && 'section-head--reverse'
      )}
    >
      {children}
    </div>
  )
}

export default SectionHead
